import React from 'react';
import ReactMarkdown from 'react-markdown';
import { DateTime } from 'luxon';
import { FrontLink } from './FrontActions';

const Date = ({ value }) => { 
  const parsedDate = DateTime.fromISO(value);

  if (parsedDate.invalid)
    return <em>Invalid date</em>;
  
  return parsedDate.toFormat('D');
};

const Currency = ({ value }) => {
  const amount = Number(value); 
  const amountString = amount < 1000 ?
    amount.toFixed(0) :
    amount < 1000000 ?
      `${Math.round(amount / 10) / 100}k` :
      `${Math.round(amount / 10000) / 100}m`;
  return <span>${amountString}</span>;
};

const Badge = ({ value }) => <span className="data-badge">{value}</span>;

const DataElement = ({ type, value, className }) => {
  if (type === 'date')
    return <div className={className}><Date value={value} /></div>;

  if (type === 'url')
    return <div className={className}><FrontLink href={value} label={value} /></div>;

  if (type === 'badge')
    return <div className={className}><Badge value={value} /></div>;
  
  if (type === 'currency')
    return <div className={className}><Currency value={value} /></div>;

  if (type === 'list' && value.length > 0)
    return <div className={className}>{value.join(', ')}</div>;

  if (type === 'markdown')
    return <ReactMarkdown linkTarget='_blank' className={className}>{value}</ReactMarkdown>;

  return <div className={className}>{value}</div>;
};

export default DataElement;